<template>
  <div class="task-detail-page">
    <Header type="book" />
    <CustomTextarea class="mt-12" label="工作汇报" v-model="textarea" :required="true" :showLimit="false"></CustomTextarea>
    <div class="recorder-images-container">
      <div class="recorder-container">
        <div class="record-label">语音</div>
        <div class="recorder-wrap">
          <Recorder ref="recorder"/>
        </div>
      </div>
      <div class="images-container">
        <ImageUploader label="图片" v-model="images" />
      </div>
    </div>
    <div class="send-container">
      <Row label="任务接收人"></Row>
      <div class="person-container">
        <Person v-for="(item, index) in receivePerson" :key="index" :person="item" :colorIndex="index"  />
      </div>
      <Row label="任务抄送人"></Row>
      <div class="person-container">
        <Person v-for="(item, index) in sendPerson" :key="index" :person="item" :colorIndex="index" />
      </div>
    </div>
    <div class="btn-container">
      <CustomButton size="full" :loading="loading" @click="handleSubmit">回复</CustomButton>
    </div>
  </div>
</template>

<script>
import CustomTextarea from '@/components/common/textArea/index.vue'
import Header from '@/components/header/headerWithNavigation/index.vue'
import Recorder from "@/components/Recorder/index.vue"
import ImageUploader from '@/components/common/imgUploadCard/index.vue'
import Person from './components/person.vue'
import CustomButton from '@/components/common/button/CustomButton.vue'
import { fetchSendPersonList } from '@/api/select'
import Row from '@/components/select/row.vue'
import { finishTask } from '@/api/task'

export default {
  components: {
    Header,
    Recorder,
    ImageUploader,
    Person,
    CustomButton,
    CustomTextarea,
    Row,
  },
  data() {
    // 2e97aef3-32c8-4d12-bfa4-22ca91e16c86
    return {
      recorderSrc: '',
      duration: 30,
      images: [],
      textarea: '',
      loading: false,
      receivePerson: [],
      sendPerson: [],
      personList: [],
    }
  },
  beforeMount() {
    this.id = this.$route.params.id
    this.sendPerson = this.$route.params.send
    this.receivePerson = this.$route.params.receive
    this.fetchSendPersonList()
  },
  methods: {
    getIdAndName(ids) {
      let ret = []
      this.personList.map(item=>{
        if (ids.includes(item.userId)) {
          ret.push({
            id: item.userId,
            name: item.nickName,
            username: item.userName
          })
        }
      })
      return ret
    },
    getPersonString(personArray) {
      let ret = []
      personArray.map(item=>{
        ret.push(item.username)
      })
      return ret.join(',')
    },
    async handleSubmit() {
      try {
        this.loading = true
        await finishTask({
          "replyPhoto": this.images.join(','),
          "id": this.id,
          "replyContent": this.textarea,
          "replyVoice": this.$refs.recorder.audioUrl,
          "replyVoiceLength": this.$refs.recorder.duration   
        })   
        this.loading = false;
        this.$toast('提交成功')
        setTimeout(()=>{
          this.$router.go(-1);
        }, 1000)               
      }
      catch(err) {
        this.loading = false;
        this.$toast('提交失败，服务器异常');
      }
    },
    async fetchSendPersonList() {
      let data = {
        "searchTotal": '',
      }
      let response = await fetchSendPersonList(data)
      this.personList = response.data || []
    },
  },
}
</script>

<style lang="scss" scoped>
.task-detail-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  position: relative;
  padding-bottom: 64px;
  box-sizing: border-box;
  .mt-12 {
    margin-top: 12px;
  }
  .status {
    width: 79px;
    height: 29px;
    background: #CD001F;
    opacity: 1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: white;
    font-size: 14px;
    justify-content: center;
  }
  .label-value-container{
    width: 100%;
    box-sizing: border-box;
    padding: 10px 24px 9px;
    font-size: 14px;
    background: white;
    text-align: left;
    .label {
      color: #91939A;
    }
    .value {
      color: #2C2C2C;
      margin-top: 6px;
    }
  }
  .recorder-images-container {
    margin-top: 12px;
    width: 100%;
    background: white;
    color: #91939A;
    font-size: 14px;
    text-align: left;
    .recorder-container {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 24px;
      .recorder-wrap {
        width: 289px;
        height: 25px;
        margin-left: 10px;
        border-radius: 5px;
      }
    }
    .images-container {
      width: 100%;
    }
  }
  .person-card-container {
    width: 100%;
    background: white;
    box-sizing: border-box;
    padding: 10px 24px 9px;
    .label {
      text-align: left;
      font-size: 14px;
      color: #818181;
    }
    .person-container {
      margin-top: 14px;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .send-container {
    margin-top: 12px;
    .person-container {
      box-sizing: border-box;
      padding: 10px 24px 0px;
      min-height: 60px;
      background: white;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .btn-container {
    width: 100%;
    margin-top: 122px;
    background: #FAFBFC;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>