<template>
  <div class="task-detail-page">
    <Header type="book" />
    <Row class="mt-12" :label="info.taskName">
      <div class="status">
        {{info.taskState === '0' ? '未完成' : '已完成'}}
      </div>
    </Row>
    <div class="label-value-container">
      <div class="label">任务描述</div>
      <div class="value">{{info.taskContent || '--'}}</div>
    </div>
    <div class="label-value-container">
      <div class="label">任务回复</div>
      <div class="value">{{info.replyContent || '--'}}</div>
      <ImageViewer 
        v-if="info.replyPhoto"
        class="replay-images"
        :images="info.replyPhoto.split(',').filter(d=>d)" />
    </div>
    <div class="recorder-images-container">
      <div class="recorder-container" v-if="info.voice && parseFloat(info.voiceLength)">
        语音
        <Recorder class="recorder" :duration="parseFloat(info.voiceLength || 0)" :src="info.voice" />
      </div>
      <div class="images-container" v-if="info.photo">
        <div class="label">图片</div>
        <ImageViewer :images="info.photo.split(',')" />
      </div>
    </div>
    <div class="person-card-container mt-12" v-if="acceptPerson.length">
      <div class="label">任务接收人</div>
      <div class="person-container">
        <Person v-for="(item, index) in acceptPerson" :key="index" :more="index===6" :person="item" :colorIndex="index" @person="handleAcceptPersonClick" />
      </div>
    </div>
    <div class="person-card-container" v-if="sendPerson.length">
      <div class="label">任务抄送人</div>
      <div class="person-container">
        <Person v-for="(item, index) in sendPerson" :key="index" :more="index===6" :person="item" :colorIndex="index" @person="handleSendPersonClick" />
      </div>
    </div>
    <div class="btn-container" v-if="type==='execute' && info.taskState === '0'">
      <CustomButton 
        size="full" 
        @click="handleFinish"
      >
        回复
      </CustomButton>
    </div>
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithNavigation/index.vue'
import Row from '@/components/select/row.vue'
import Recorder from '@/components/Recorder/recorderDisplay.vue'
import ImageViewer from '@/components/common/imageViewer/index.vue'
import Person from './components/person.vue'
import CustomButton from '@/components/common/button/CustomButton.vue'
import { Loading } from 'vant'
import { fetchTaskDetail } from '@/api/task'

export default {
  components: {
    Header,
    Row,
    Recorder,
    ImageViewer,
    Person,
    CustomButton,
    Loading,
  },
  data() {
    return {
      info: {
        taskSendName: '',
        taskReceiveName: ''
      },
      taskName: '史丹利h5ui界面设计',
      status: '已完成',
      description: 'xxxxxxxxxxx',
      // acceptPerson: ,
      // sendPerson: ['史提芬'],
      recorderSrc: '',
      duration: 30,
      images: [],
      isLoading: false,
      id: '',
      type: ''
    }
  },
  computed: {
    sendPerson() {
      return this.info.taskSendName ? this.info.taskSendName.split(',').slice(0, 7) : []
    },
    acceptPerson() {
      return this.info.taskReceiveName ? this.info.taskReceiveName.split(',').slice(0, 7) : []
    }
  },
  async activated() {
    try {
      this.id = this.$route.params.id
      this.type = this.$route.query.type
      this.isLoading = true
      let response = await fetchTaskDetail({
        id: this.id
      })
      this.info = response.data
      this.isLoading = false
    }
    catch(err) {
      this.$toast(err.message)
    }
  },
  methods: {
    handleFinish() {
      this.$router.push({
        name: 'TaskFinishTask',
        params: {
          id: this.id,
          send: this.info.taskSendName.split(',').filter(d => d) || [],
          receive: this.info.taskReceiveName.split(',').filter(d => d) || []
        }
      })
    },
    handleAcceptPersonClick() {
      this.$router.push({
        name: 'TaskReceiveList',
        params: {person: this.info.taskReceiveName.split(',')},
      })
    },
    handleSendPersonClick() {
      this.$router.push({
        name: 'TaskSendList',
        params: {person: this.info.taskSendName.split(',')},
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.task-detail-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  position: relative;
  padding-bottom: 64px;
  box-sizing: border-box;
  .mt-12 {
    margin-top: 12px;
  }
  .status {
    width: 79px;
    height: 29px;
    background: #CD001F;
    opacity: 1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: white;
    font-size: 14px;
    justify-content: center;
  }
  .label-value-container{
    width: 100%;
    box-sizing: border-box;
    padding: 10px 24px 9px;
    font-size: 14px;
    background: white;
    text-align: left;
    .label {
      color: #91939A;
    }
    .value {
      color: #2C2C2C;
      margin-top: 6px;
    }
    .replay-images {
      margin-top: 13px;
    }
  }
  .recorder-images-container {
    margin-top: 12px;
    width: 100%;
    background: white;
    color: #91939A;
    font-size: 14px;
    text-align: left;
    .recorder-container {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 24px;
      .recorder {
        margin-left: 10px;
      }
    }
    .images-container {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 24px 19px;
      .label {
        margin-bottom: 13px;
      }
    }
  }
  .person-card-container {
    width: 100%;
    background: white;
    box-sizing: border-box;
    padding: 10px 24px 9px;
    .label {
      text-align: left;
      font-size: 14px;
      color: #818181;
    }
    .person-container {
      margin-top: 14px;
      display: flex;
      flex-wrap: nowrap;
    }
  }
  .btn-container {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #FAFBFC;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>