<template>
  <div class="task-list-page">
    <HeaderTabs
      :tabs="tabs"
      :value="activeName"
      @click="handleTabClick"
    >
      <div class="icon-wrap">
        <img class="add-icon" :src="AddIcon" alt="" @click="$router.push({name: 'TaskPublishTask'})" />
      </div>
    </HeaderTabs>
    <div class="card-list">
      <Card class="card" @click="handleCardClick" v-for="(item, index) in list" :key="index" :info="item" :config="config" />
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
  </div>
</template>
<script src="../../utils/user.js"></script>
<script>
import HeaderTabs from '@/components/header/headerTabs/index.vue'
import AddIcon from '@/assets/images/common/add.png'
import Card from './components/listCard.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { fetchGetTaskList, fetchGiveTaskList } from '@/api/task'
import { getUserName } from "@/utils/user"
export default {
  components: {
    HeaderTabs,
    Card,
    InfiniteLoading,
  },
  data() {
    return {
      AddIcon,
      activeName: "我发布的任务",
      tabs: [{ title: "我发布的任务" }, { title: "我执行的任务" }],
      pageNo: 1,
      pageSize: 5,
      total: 0,
      list: [],
      config: [
        {label: '工作名称', field: 'taskName', type: 'one'},
        {label: '发起人员', field: 'createByName', type: 'one'},
        {label: '执行人员', field: 'taskReceiveName', type: 'one'},
        {label: '下达时间', field: 'createTime', type: 'one'}
      ]
    }
  },
  activated() {
    if (this.$route.query.type === "1") {
      this.handleTabClick('我执行的任务')
    } else {
      this.handleTabClick('我发布的任务')
    }
  },
  methods: {
    handleCardClick(info) {
      if (this.activeName === '我发布的任务') {
        this.$router.push(`/task/detail/${info.id}?type=pulish`)
        // this.$router.push({
        //   name: 'TaskDetail',
        //   params: {id: info.id, type: 'pulish'}
        // })
      }
      else if(this.activeName === '我执行的任务') {
        this.$router.push(`/task/detail/${info.id}?type=execute`)
        // this.$router.push({
        //   name: 'TaskDetail',
        //   params: {id: info.id, type: 'execute'}
        // })
      }
    },
    resetList() {
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    handleTabClick(tab) {
      this.activeName = tab;
      this.resetList()
    },
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async fetchList() {
      // let response = []
      // let data = {
      //   "taskSource": "0,1",
      //   "page.pageSize": this.pageSize,
      //   "taskState": "0",
      //   "taskReceiveType": "0",
      //   "page.pageNo": this.pageNo
      // }
      let response = [];
      let data={};
      if (this.activeName === '我发布的任务') {
          data = {
              "taskSource": "0,1",
              "page.pageSize": this.pageSize,
              "taskState": "0",
              "taskReceiveType": "0",
              "createBy": getUserName(),
              "page.pageNo": this.pageNo
          }
      }else if(this.activeName === '我执行的任务') {
           data = {
              "taskSource": "0,1",
              "page.pageSize": this.pageSize,
              "taskState": "0",
              "taskReceiveType": "0",
              "taskReceive": getUserName(),
              "page.pageNo": this.pageNo
          }
      }
      if (this.activeName === '我发布的任务') {
        response = await fetchGiveTaskList(data)
      }
      else if(this.activeName === '我执行的任务') {
        response = await fetchGetTaskList(data)
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.task-list-page {
  width: 100%;
  height: 100vh;
  background: #FAFBFC;
  .icon-wrap {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 24px;
    display: flex;
  }
  .add-icon {
    width: 100%;
    height: 100%;
  }
  .card-list {
    padding-top: 12px;
    .card:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
</style>