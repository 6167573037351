import { post } from '@/utils/request';

export const fetchGetTaskList = async data => {
  return await post('/appdealer/api/taskrecord/getGetTaskRecordList', data)
}

export const fetchGiveTaskList = async data => {
  return await post('/appdealer/api/taskrecord/getGiveTaskRecordList', data)
}

export const saveTask = async data => {
  return await post('/appdealer/api/taskrecord/saveTaskRecord', data)
}

export const finishTask = async data => {
  return await post('/appdealer/api/taskrecord/saveTaskRecordReply', data)
}

export const fetchTaskDetail = async data => {
  return await post('/appdealer/api/taskrecord/getTaskRecordById', data)
}