<template>
  <Card class="container" @click.native="handleClick">
    <div class="card">
      <!-- <div class="title">{{info.taskName}}</div> -->
      <div 
        class="label-value-container" 
        v-for="(item, index) in config" 
        :key="index"
        :class="{'one-line': item.type==='one', 'two-line': item.type==='two'}"
      >
        <div class="label">{{item.label}}</div>
        <div class="value">{{info[item.field]}}</div>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/common/card/whiteCard.vue'

export default {
  components: {
    Card,
  },
  props: {
    info: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    config: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  methods: {
    handleClick() {
      this.$emit('click', this.info)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .card {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 13px;
    .title {
      color: #2C2C2C;
      font-weight: bold;
      margin-bottom: 6px;
    }
    .label-value-container {
      .label {
        color: #818181;
      }
      .value {
        color: #2C2C2C;
      }
    }
    .one-line {
      height: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      text-overflow: ellipsis;
      .label {
        
      }
      .value {
        margin-left: 6px;
      }
    }
    .two-line {
      .label {
        height: 20px;
        margin-bottom: 5px;
      }
      .value {
        word-wrap: break-word;
        min-height: 20px;   
      }
    }

  }
}
</style>