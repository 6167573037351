<template>
  <div class="container" @click="more && $emit('person')">
    <div class="first" :style="{background: !more ? color[colorIndex%color.length] : '#E4E4E4'}">
      {{more ? '' : person[0] && person[0].toUpperCase()}}
      <img class="more-icon" v-if="more" :src="MoreIcon" />
    </div>
    <div class="name">
      {{more ? '' : person}}
    </div>
  </div>
</template>

<script>
import MoreIcon from '@/assets/images/common/more.png'

export default {
  props: {
    person: {
      type: String,
      default: ''
    },
    colorIndex: {
      type: Number,
      default: 0
    },
    more: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      MoreIcon,
      color: ['#335AFF', '#FF4A9C']
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  flex-shrink: 0;
  width: 48px;
  height: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  position: relative;
  .first {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    color: white;
    border-radius: 50%;
    position: relative;
    .more-icon {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .name {
    min-width: 1px;
    min-height: 14px;
    font-size: 10px;
    color: #818181;
    margin-top: 2px;
  }
}
</style>