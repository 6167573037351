<template>
  <div class="container">
    <div class="circle" :style="{background: color[colorIndex%color.length]}">{{person[0] && person[0].toUpperCase()}}</div>
    <div class="name">{{person}}</div>
  </div>
</template>

<script>
export default {
  props: {
    person: {
      type: String,
      default: ''
    },
    colorIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      color: ['#335AFF', '#FF4A9C']
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 25px;
  background: white;
  .circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
    color: white;
    border-radius: 50%;
  }
  .name {
    margin-left: 6px;
    font-size: 13px;
    color: #2C2C2C;
  }
}
</style>