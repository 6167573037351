<template>
  <div class="publish-task-page">
    <Header type="book" />
    <div class="main-container">
      <Row label="任务名称" :required="true" >
        <Input type="text" v-model="taskName" />
      </Row>
      <CustomTextarea label="任务描述" v-model="textarea" :required="true" :showLimit="false" />
      <Row label="语音">
        <div class="recorder-wrap">
          <Recorder ref="recorder" />
        </div>
      </Row>
      <ImageUpload label="图片" :limit="9" v-model="images" />
    </div>
    <div class="send-container">
      <Row label="任务接收人" :required="true">
        <Picker v-if="!defaultSend.name" v-model="receivePerson" labelString="nickName" :showLabel="false" valueString="userId" :data="personList" />
      </Row>
      <div class="person-container">
        <Person v-for="(item, index) in receivePersonComputed" :key="index" :person="item.name || ''" :colorIndex="index"  />
      </div>
      <Row label="任务抄送人" :required="true">
        <Picker v-model="sendPerson" labelString="nickName" valueString="userId" :data="personList" :single="false" />
      </Row>
      <div class="person-container">
        <Person v-for="(item, index) in sendPersonComputed" :key="index" :person="item.name || ''" :colorIndex="index" />
      </div>
      <div class="switch-container">
        <Row label="给任务执行人发送短信" :required="false">
          <CustomSwitch :value="switchValue" v-model="switchValue" />
        </Row>
      </div>
      <div class="btn-container">
        <CustomButton :loading="loading" size="full" @click="handleSubmit">提交</CustomButton>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithNavigation/index.vue'
import Row from '@/components/select/row.vue'
import Input from '@/components/common/input/index.vue'
import CustomTextarea from '@/components/common/textArea/index.vue'
import Recorder from '@/components/Recorder/index.vue'
import ImageUpload from '@/components/common/imgUploadCard/index.vue'
import Picker from '@/components/select/picker.vue'
import Person from './components/person.vue'
import CustomSwitch from '@/components/select/switch.vue'
import CustomButton from '@/components/common/button/CustomButton.vue'
import { saveTask } from '@/api/task'
import { fetchSendPersonList } from '@/api/select'

export default {
  components: {
    Header,
    Row,
    Input,
    CustomTextarea,
    Recorder,
    ImageUpload,
    Picker,
    Person,
    CustomSwitch,
    CustomButton,
  },
  data() {
    return {
      acceptList: [],
      copyList: [],
      switchValue: false,
      taskName: '',  
      textarea: '',
      receivePerson: [],
      sendPerson: [],
      personList: [],
      images: [],
      defaultSend: '',
      loading: false
    }
  },
  computed: {
    receivePersonComputed() {
      return this.defaultSend.name ? [this.defaultSend].concat(this.getIdAndName(this.receivePerson)) : this.getIdAndName(this.receivePerson)
    },
    sendPersonComputed() {
      return this.getIdAndName(this.sendPerson)
    },
  },
  methods: {
    getIdAndName(ids) {
      let ret = []
      this.personList.map(item=>{
        if (ids.includes(item.userId)) {
          ret.push({
            id: item.userId,
            name: item.nickName,
            username: item.userName
          })
        }
      })
      return ret
    },
    getPersonString(personArray) {
      let ret = []
      personArray.map(item=>{
        ret.push(item.username)
      })
      return ret.join(',')
    },
    changeSwitchValue() {
      this.switchValue = !this.switchValue
    },
    async handleSubmit() {
      const voice = this.$refs.recorder.audioUrl;
      const duration = this.$refs.recorder.duration;
      this.loading = true
      try {
        await saveTask({
          "taskType": "其他类型",
          "voiceLength": duration,
          "taskContent": this.textarea,
          "taskSend": this.getPersonString(this.sendPersonComputed),
          "taskName": this.taskName,
          "smsPerson": this.switchValue ? '1' : '0',
          "voice": voice,
          "photo": this.images.join(','),
          "taskReceive": this.getPersonString(this.receivePersonComputed)
        })
        this.$toast('成功提交')
        setTimeout(()=>{
          this.$router.replace({name: 'Home'})
        })
      }
      catch (err) {
        this.$toast(err.message)
      }
      this.loading = false
    },
    async fetchSendPersonList() {
      let data = {
        "searchTotal": '',
      }
      let response = await fetchSendPersonList(data)
      this.personList = response.data || []
    },
  },
  beforeMount() {
    if (this.$route.params.sendUser){
      this.defaultSend = {
        id: this.$route.params.sendUser.userId || '', 
        name: this.$route.params.sendUser.nickName || '', 
        username: this.$route.params.sendUser.userName || ''
      }
    }
    this.fetchSendPersonList()
  }
}
</script>

<style lang="scss" scoped>
.publish-task-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .main-container {
    .recorder-wrap {
      width: 289px;
      height: 29px;
    }
  }
  .send-container {
    margin-top: 12px;
    .person-container {
      box-sizing: border-box;
      padding: 10px 24px 0px;
      min-height: 60px;
      background: white;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .switch-container {
    margin-top: 12px;
  }
  .btn-container {
    width: 100%;
    margin-top: 21px;
  }
}
</style>