<template>
  <div class="task-sendlist-page">
    <Header />
    <div class="list-container">
      <PersonLine class="person" v-for="(item, index) in list" :key="index" :person="item" :colorindex="index" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import PersonLine from './components/personLine.vue'

export default {
  components: {
    Header,
    PersonLine,
  },
  data() {
    return {
      list: ['史提芬','史提芬','史提芬']
    }
  }
}
</script>

<style lang="scss" scoped>
.task-sendlist-page {
  width: 100%;
  height: 100vh;
  background: #FAFBFC;
  .list-container {
    padding-top: 12px;
    .person {
      margin-bottom: 6px;
    }
  }
}
</style>